import React, { useEffect } from 'react';
import Slider from "react-slick";

// Función para cargar el script de Pinterest
const loadPinterestScript = () => {
  if (window.PIN) {
    window.PIN.load();
  } else {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = "//assets.pinterest.com/js/pinit.js";
    document.body.appendChild(script);
  }
};

const PinterestCarouselEllas = () => {
  const backgroundImageUrl = './bg2.png';
  useEffect(() => {
    loadPinterestScript();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Número predeterminado de slides para pantallas grandes
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Breakpoint para tabletas y pantallas medianas
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600, // Breakpoint para pantallas pequeñas (celulares)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };


  return (
    <Slider {...settings} style={{ 
      backgroundImage: `url(${backgroundImageUrl})`,
      backgroundRepeat: 'repeat', // Esto hará que la imagen se repita en mosaico
      backgroundSize: 'auto', // Asegura que la imagen no se estire
      backgroundPosition: 'left top' // Alinea la imagen al principio del contenedor
    }}>
    <div>
      <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329548/"></a>
    </div>
    <div>
      <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329932/"></a>
    </div>
    <div>
      <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329585/"></a>
    </div>
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329632/"></a>
      </div>
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329955/"></a>
      </div>
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329623/"></a>
      </div>
      {/* Agrega más pines según sea necesario */}
      
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329643/"></a>
      </div>
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329748/"></a>
      </div>
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014330518/"></a>
      </div>
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329559/"></a>
      </div>
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014330528/"></a>
      </div>
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014330144/"></a>
      </div>
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329958/"></a>
      </div>
    </Slider>
  );
};

export default PinterestCarouselEllas;
