import React from 'react';
import Countdown from './Countdown';

const Section1 = () => {
  const backgroundImageUrl = './bg2.png';

  return (
    <section id="section1" className="flex flex-col min-h-screen"
    style={{ 
      backgroundImage: `url(${backgroundImageUrl})`,
      backgroundRepeat: 'repeat', // Esto hará que la imagen se repita en mosaico
      backgroundSize: 'auto', // Asegura que la imagen no se estire
      backgroundPosition: 'left top' // Alinea la imagen al principio del contenedor
    }}>
      {/* style={{ //opcional para imagen de fondo
      backgroundImage: `url(${backgroundImageUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', // Cubre completamente el contenedor sin deformar la imagen
      backgroundPosition: 'left top',
      width: '100vw', // Ancho del contenedor al 100% del ancho de la ventana
      height: '100vh', // Altura del contenedor al 100% de la altura de la ventana
      opacity: 0.7, // Opacidad de la imagen (0 completamente transparente, 1 completamente opaca)
    }}> */}
      <main className="flex-grow container mx-auto flex flex-col items-center justify-center mt-[-10vh]">
        <img src="ALESSANDRAEMILIO.png" alt="nombres" width={"90%"}/>
        <p className="text-2xl md:text-5xl mt-6 custom-font-family text-[#7E6E5F] p-8">22 | OCT | 24</p>
        <p className="uppercase tracking-widest mt-8 text-3xl">
        <Countdown 
          timeTillDate="10 22 2024, 3:00 pm" 
          timeFormat="MM DD YYYY, h:mm a" 
        />
        </p>
      </main>
    </section>
  );
};
export default Section1;
