import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import PinterestBoardEllos from './components/PinterestCarouselEllos';
import PinterestBoardEllas from './components/PinterestCarouselEllas';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
      <>
      <Header/>
     <Section1 id='section1'></Section1>
     <Section2 id='section2'></Section2>
     <Section3 id='section3'></Section3>
     <Section4 id='section4'></Section4>
     <PinterestBoardEllos id='section5' ></PinterestBoardEllos>
     <PinterestBoardEllas id='section6' ></PinterestBoardEllas>
    </>
  );
}

export default App;
