import React, { useEffect } from 'react';
import Slider from "react-slick";

// Función para cargar el script de Pinterest
const loadPinterestScript = () => {
  if (window.PIN) {
    window.PIN.load();
  } else {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = "//assets.pinterest.com/js/pinit.js";
    document.body.appendChild(script);
  }
};

const PinterestCarouselEllos = () => {
  const backgroundImageUrl = './bg2.png';
  useEffect(() => {
    loadPinterestScript();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Número predeterminado de slides para pantallas grandes
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Breakpoint para tabletas y pantallas medianas
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600, // Breakpoint para pantallas pequeñas (celulares)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };  
  
  const handleClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };


  return (<section id="section5" className="flex flex-col min-h-screen" style={{ 
      backgroundImage: `url(${backgroundImageUrl})`,
      backgroundRepeat: 'repeat', // Esto hará que la imagen se repita en mosaico
      backgroundSize: 'auto', // Asegura que la imagen no se estire
      backgroundPosition: 'left top' // Alinea la imagen al principio del contenedor
    }}>
  <div className="bg-beige-100 p-8 md:pl-80 md:pr-80 text-center text-[#4D5354]">
  <a target="_blank" href='https://www.pinterest.com/schuchardt3526/b-tenida-invitados/' onClick={() => handleClick('https://www.pinterest.com/schuchardt3526/b-tenida-invitados/')}>
  <h1 className="text-xl md:text-5xl font-regular custom-font-family text-[#7E6E5F] pb-4">OUTFITS</h1>
  {/* <h2 className="text-xl md:text-5xl font-regular custom-font-family text-[#7E6E5F] ">Haz click aqui para ver el board completo!</h2> */}
  <h3 className="text-sm   md:text-xl font-regular">Haz click aqui para ver el board completo!</h3></a>
  </div><Slider {...settings}>
    {/* <div onClick={() => handleClick('https://www.pinterest.com/schuchardt3526/b-tenida-invitados/')}> */}
    <div>
      <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329717/"></a>
    </div>
    <div>
      <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329733/"></a>
    </div>
    <div>
      <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329709/"></a>
    </div>
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329729/"></a>
      </div>  
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329732/"></a>
      </div>
      <div>
        <a data-pin-do="embedPin" href="https://www.pinterest.com/pin/794885403014329700/"></a>
      </div>
    {/* </div> */}
      {/* Agrega más pines según sea necesario */}
    </Slider></section>
  );
};

export default PinterestCarouselEllos;
