import React from 'react';
import { FaPerson } from "react-icons/fa6";
import { FaChild } from "react-icons/fa6";
import { PiWhatsappLogo } from "react-icons/pi";

const Section3 = () => {
    const backgroundImageUrl = './bg2.png';
    return (
        <section id="section3" className="flex flex-col"
        style={{ 
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundRepeat: 'repeat', // Esto hará que la imagen se repita en mosaico
          backgroundSize: 'auto', // Asegura que la imagen no se estire
          backgroundPosition: 'left top' // Alinea la imagen al principio del contenedor
        }}>
        <div className="container mx-auto px-4 md:py-8 py-1">
          <div className='grid grid-cols-1 md:grid-cols-2 '>
          <div className="text-left mb-6 grid content-center" >
            <h2 className="text-xl md:text-5xl font-regular custom-font-family text-[#7E6E5F] ">El paquete incluye:</h2>
            <ul className="list-disc text-[11px] md:text-base text-left mt-6 pl-14 text-[#4D5354]" style={{ maxWidth: '600px' }}>
              <li>TICKET AÉREO</li>
              <li>5 NOCHES DE ALOJAMIENTO, ALL INCLUSIVE</li>
              <li>TRASLADOS AEROPUERTO - HOTEL - AEROPUERTO</li>
            </ul>
          </div>
          <div className='max-w-prose p-[2vh] md:p-[vh]'>
            <a href="https://my.matterport.com/show/?m=L27pHTgRcFT" target='_blank' ><img data-src="lagon_view.jpg" className='rounded-2xl drop-shadow-2xl'  alt="room" src="lagon_view.jpg"/>
          </a>
          
          </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-[#4D5354]">
            <div className="flex flex-col items-left">
              <div className="flex items-center mb-4">
                <FaPerson className="text-4xl mr-4 text-[#7E6E5F]" />
                <div className='flex flex-col md:flex-row'>
                  <h3 className="text-sm   md:text-xl font-regular">EL PRECIO POR CADA &nbsp;</h3>
                  <h3 className="text-sm   md:text-xl font-regular"><b>PASAJERO ADULTO</b> ES DE:</h3>
                </div>
              </div>
              <div className="list-disc text-[11px] md:text-base"><br />
                <p className='text-[11px] md:text-xl'>• <b>1830 US$</b> - JUNIOR SUITE <b>DOBLE</b> GARDEN VIEW / LAGOON VIEW</p>
                <p className="ml-6"><br />+460 US$ - UPGRADE A FAMILY SELECTION DOBLE JUNIOR SUITE BEACHSIDE OCEANVIEW</p>
                <p className="ml-6"><br />+500 US$ - UPGRADE A JUNIOR SUITE DOBLE POOLSIDE OCEANVIEW</p>
                <br /><br />
              </div>
              <div className="list-disc text-[11px] md:text-base">
                <p className='text-[11px] md:text-xl'>• <b>1810 US$</b>- JUNIOR SUITE <b>TRIPLE</b> GARDEN VIEW / LAGOON VIEW</p>
                <p className="ml-6"><br />+460 US$ - UPGRADE A FAMILY SELECTION TRIPLE JUNIOR SUITE BEACHSIDE OCEANVIEW</p>
                <p className="ml-6"><br />+500 US$ - UPGRADE A JUNIOR SUITE TRIPLE POOLSIDE OCEANVIEW</p>
                <br />
              </div>
            </div>
            <div className="flex flex-col items-left">
              <div className="flex items-center mb-4">
                <FaChild className="text-4xl mr-4 text-[#7E6E5F]" />
                <div className='flex flex-col md:flex-row'>
                <h3 className="text-sm   md:text-xl font-regular">EL PRECIO POR CADA &nbsp;</h3>
                <h3 className="text-sm   md:text-xl font-regular"><b>PASAJERO MENOR</b> ES DE:</h3>
                </div>
              </div>
              <div className="list-disc text-[11px] md:text-base"><br />
                <p className='text-[11px] md:text-xl'>• <b>1430 US$</b> - MENORES DE <b>2 A 11 AÑOS</b></p>
                <p className="ml-6"><br />+320 US$ - UPGRADE A FAMILY SELECTION DOBLE JUNIOR SUITE BEACHSIDE OCEANVIEW</p>
                <p className="ml-6"><br />+350 US$ - UPGRADE A JUNIOR SUITE DOBLE POOLSIDE OCEANVIEW</p>
                <br /><br />
              </div>
              <div className="list-disc text-[11px] md:text-base">
                <p className='text-[11px] md:text-xl'>• <b>200 US$</b> - MENORES DE <b>0 A 1 AÑO y 11 MESES</b></p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="pl-4 pr-4 mt-4 max-w-md mx-auto border-[#7E6E5F] rounded-full radius custom-font-family">
                <h2 className="font-semibold mb-2 font-2xl md:text-2xl text-[#4D5354]">[SEÑA: 170 US$  POR  PERSONA]</h2>
            </div>
        <div className='flex justify-center flex-col md:flex-row items-center mt-6'>
          <div className='pb-2 md:pb-2'>
            <div className="text-4xl text-[#7E6E5F] text-center" ><PiWhatsappLogo className="mx-auto text-6xl text-[#7E6E5F]" /></div>
        <h1 className="text-3xl md:text-7xl font-regular custom-font-family text-[#7E6E5F] pb-4 text-center">rsvp</h1>
        
        </div>
        <div>
        <div className="mb-4 font-regular text-center text-[#4D5354] list-disc text-[11px] md:text-base md:pl-6">
        <div className="pl-4 pr-4 mb-4 border-2 max-w-md mx-auto border-[#7E6E5F] rounded-full radius custom-font-family">
          <a target="_blank" href='https://wa.me/5950985834465?text=Hola, me comunico para mas informacion de la boda de Emilio%26Sandy!'> 
            <h2 className="font-semibold md:mb-2 font-2xl text-xs md:text-base text-[#7E6E5F]">Favor confirmar asistencia</h2>
          </a>
        </div>
            <a target="_blank" href='https://wa.me/5950985834465?text=Hola, me comunico para mas informacion de la boda de Emilio%26Sandy!'> 
            <p><b>AGENCIA CMP TRAVEL</b></p>
            <p>ALEJANDRA RIQUELME</p>
            <p>+595 985 834 465</p>
            <p>ARIQUELME@CMP.COM.PY</p></a>
        </div></div>
        </div>



        </section>
      );
    };
    
export default Section3;
