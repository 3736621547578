import React from 'react';

const Section2 = () => {
    const backgroundImageUrl = './bg3.png';

    return (
      <section id="section2" className="flex flex-col min-h-screen"
      style={{ 
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundRepeat: 'repeat', // Esto hará que la imagen se repita en mosaico
        backgroundSize: 'auto', // Asegura que la imagen no se estire
        backgroundPosition: 'left top' // Alinea la imagen al principio del contenedor
      }}>
        <main className="flex-grow container mx-auto flex flex-col items-center justify-top mt-10">
          <div className='grid grid-cols-1 md:grid-cols-2 '>
          <div className="max-w-prose text-left mb-6 grid content-center justify-center" >
            {/* TODO: CAMBIAR LA PORTADA Y EL PDF ACTUALIZADO */}
            <a href='https://1drv.ms/b/s!AoG7-eQBvpnvoEcJ0RJirfxhcETQ?e=a3DL1l' target='_blank' className='shadow-md'>
              <img data-src="invitacion.png" src="invitacion.png" className='drop-shadow-2xl max-w-48 md:max-w-full ' />
            </a>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 '>
          <div className="text-left mb-6 grid content-start" >
            <h2 className="text-xl md:text-2xl font-regular custom-font-family text-[#7E6E5F] text-center">GRAND PALLADIUM <br />COSTA MUJERES RESORT & SPA</h2>
            <div className="text-[11px] p-[2vh] md:p-0 md:text-2xl text-justify text-[#4D5354]">
              Costa Mujeres es una nueva zona hotelera 
              que inicio su desarrollo en 2019 con playas
               paradisiacas y resort totalmente nuevos 
               para seguir explorando el Caribe Mexicano.
            </div>
          </div>
          <div className="w-full h-64 p-[2vh] md:h-64 md:p-0 md:pl-[2vh]">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12413.259240933887!2d-86.8143175!3d21.264811186854537!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c31f0b3477e9b%3A0x1a8df8e22c0c2b02!2sGrand%20Palladium%20Costa%20Mujeres%20Resort%20%26%20Spa!5e1!3m2!1ses-419!2spy!4v1703339467006!5m2!1ses-419!2spy"
        style={{ border: 0, width: "100%", height: "100%" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      />
    </div>
          </div>
          </div>
        </main>
      </section>
  );
};

export default Section2;
