import React from 'react';
import { Link } from 'react-scroll';
import { FaGift, FaHome } from 'react-icons/fa'; 

const Header = () => {
  return (
    <header className="sticky top-0 z-10 bg-white shadow-md">
      <div className="container mx-auto flex justify-between items-center p-2 md:p-4">
      <Link to="section1" smooth={true} duration={500}><img src='AE2.png' alt="logo" className="h-6 md:h-8 flex-shrink-0"/></Link>
        <ul className="flex justify-center space-x-4">
        <li className="text-[#7E6E5F] md:text-2xl hover:text-[#b5a294]">
          <Link to="section1" smooth={true} duration={500} className="transition-colors duration-300"><FaHome className="mx-auto mt-1 " /></Link>
        </li>
        <li className="text-[#7E6E5F] md:text-2xl hover:text-[#b5a294]">
          <Link to="section2" smooth={true} duration={500} className="transition-colors duration-300">Intro</Link>
        </li>
        <li className="text-[#7E6E5F] md:text-2xl hover:text-[#b5a294]">
          <Link to="section3" smooth={true} duration={500} className="transition-colors duration-300">Paquete</Link>
        </li>
        <li className="text-[#7E6E5F] md:text-2xl hover:text-[#b5a294]">
          <Link to="section5" smooth={true} duration={500} className="transition-colors duration-300">Outfit</Link>
        </li>
        <li className="text-[#7E6E5F] md:text-2xl hover:text-[#b5a294]">
          <Link to="section4" smooth={true} duration={500} className="transition-colors duration-300"><FaGift className="mx-auto mt-1 " /></Link>
        </li>
      </ul>

      </div>
    </header>
  );
};

export default Header;
