import React from 'react';
import { FaGift } from 'react-icons/fa'; // Asegúrate de haber instalado react-icons
import { GiClick } from "react-icons/gi";

const Section4 = () => {
    const backgroundImageUrl = './bg3.png';
    return (
        <section id="section4" className="flex flex-col"
        style={{ 
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundRepeat: 'repeat', // Esto hará que la imagen se repita en mosaico
          backgroundSize: 'auto', // Asegura que la imagen no se estire
          backgroundPosition: 'left top' // Alinea la imagen al principio del contenedor
        }}>
        <div className="bg-beige-100 p-8 md:pl-80 md:pr-80 text-center text-[#4D5354]">
            <FaGift className="mx-auto mb-4 text-6xl text-[#7E6E5F]" /> {/* Icono de regalo */}
            <h1 className="text-xl md:text-5xl font-regular custom-font-family text-[#7E6E5F] pb-4">FAMILIA Y AMIGOS</h1>
            <p className="mb-4 list-disc text-[11px] md:text-base">
                EN ESTE DÍA TAN ESPECIAL, EL MEJOR REGALO ES SU COMPAÑIA!
            </p>
            <p className="mb-4 list-disc text-[11px] md:text-base">
                PERO SI QUIEREN AYUDARNOS A EQUIPAR NUESTRO HOGAR, HABILITAMOS LA SIGUIENTE CUENTA BANCARIA:
            </p>
            <div className="mb-4 font-semibold list-disc text-[11px] md:text-base">
                <p>BANCO ITAÚ - NRO. DE CTA. 400003665</p>
                <p>ALESSANDRA SCHUCHARDT</p>
                <p>C.I. 3.983.724</p>
            </div>
           
            <p className="mb-4 list-disc text-[11px] md:text-base">
                O SUMAR KILÓMETROS A NUESTRA LUNA DE MIEL
            </p>
            {/* TODO: CAMBIAR EL TEXTO QUE SE ENVIA */}
            <a target="_blank" href='https://wa.me/5950985834465?text=Hola, me comunico para dar un regalito en forma de GIFT CARD para Emilio%26Sandy!'> 
            <div className="text-[11px] md:text-base p-4 border-2 border-dashed max-w-md mx-auto border-[#7E6E5F] rounded-xl relative">
                <h2 className="font-bold mb-2 ">GIFT CARD - CMP TRAVEL</h2>
                <p>
                CONTACTOS AL 0985 834 465
                </p>
                <p>
                CON ALEJANDRA RIQUELME
                </p>
                <GiClick className="absolute right-0 bottom-0 m-2 text-5xl text-[#7E6E5F]" />
            </div>
            </a>
        </div>
    </section>
  );
};

export default Section4;
